<script>
import {findLote} from "@/domain/leiloes/services"
import printRecibo from "@/components/pda/print/recibo"

export default {
  mixins: [],
  nome: 'PrintRecibo',
  created() {
    console.log('FOIIIII!!!')
    this.isLoading = true
    findLote(this.$route.params.id)
        .then((response) => {
          this.printRecibo([response.data])
          this.isLoading = false
        })
        .catch((response) => {
          // this.isLoading = false
          this.alertApiError(response)
          console.log(response)
        })
  },
  methods: {
    printRecibo
  }
}
</script>

<template>
  <div>Aguarde...</div>
</template>
